import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * Renders the CustomerExcess component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.baseUrl - The base URL.
 * @param {boolean} props.reload - Indicates if the component should reload.
 * @param {string} props.sumTypeUrl - The sum type URL.
 * @param {string} props.resourceNameClaim - The resource name claim.
 * @returns {JSX.Element} The rendered CustomerExcess component.
 */
export const CustomerExcess = ({
  baseUrl,
  reload,
  sumTypeUrl,
  resourceNameClaim
}) => {
  const [totalExcess, setTotalExccess] = useState(0);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getGlobalPaymentAmount = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${resourceNameClaim}/${id}`
    });
    return data.accounting_provision;
  };

  const getTotalExcess = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${baseUrl}/${sumTypeUrl}/${id}/${resourceNameClaim}`
    });
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const totalExcessAmount = await getTotalExcess();
      const globalPayment = await getGlobalPaymentAmount();
      setTotalExccess((globalPayment || 0) - (totalExcessAmount || 0));
    };
    fetchData();
  }, [reload]);

  return <Typography>{totalExcess?.toFixed(2)} €</Typography>;
};

CustomerExcess.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  sumTypeUrl: PropTypes.string.isRequired,
  reload: PropTypes.bool.isRequired,
  resourceNameClaim: PropTypes.string.isRequired
};
