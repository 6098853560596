import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dropdown, Typography } from 'antd';
import { DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ExportXlsxButton } from '../../../../components/ExportXlsxButton/ExportXlsxButton';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Text } = Typography;

/**
 * ExportAllVehicleFleetsMenu component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.target - The target of the menu (either 'programmes' or 'claims').
 *
 * @returns {JSX.Element} The ExportAllVehicleFleetsMenu component.
 */
export const ExportAllVehicleFleetsMenu = ({ target }) => {
  const { company } = useAuthContext();
  const { t } = useTranslation();
  const baseQuey = `deleted=false&company=${company}&fleet_type=LIGHT_VEHICLE,HEAVY_VEHICLE,OTHER_VEHICLE`;
  const isClosedQuery = '&claim_status=CLOSED';
  const isOpenQuery = '&claim_status!=CLOSED';

  const isClosedClaim = {
    url: `exports/company/${company}/programme-type/vehicle_claim?fleet_type=ALL_VEHICLE_claim`,
    buttonName: t('buttons.exports.claims.all_closed_fleet_types'),
    extraQuery: `${baseQuey}${isClosedQuery}`,
    resource: 'vehicle_claims',
    populate: 'entity,site,entry_id,company'
  };
  const isOpenClaim = {
    ...isClosedClaim,
    buttonName: t('buttons.exports.claims.all_open_fleet_types'),
    extraQuery: `${baseQuey}${isOpenQuery}`
  };
  const allClaims = {
    ...isClosedClaim,
    buttonName: t('buttons.exports.claims.all_fleet_types'),
    extraQuery: `${baseQuey}`
  };

  const menuClaimExports = {
    items: [
      {
        key: 'title',
        label: (
          <Text strong>
            <InfoCircleOutlined style={{ marginRight: '18px' }} />
            {t('buttons.exports.all_outside_automission')}
          </Text>
        )
      },
      {
        key: 'export',
        label: (
          <ExportXlsxButton
            exportUrl={`/${allClaims?.url}`}
            populate={allClaims?.populate}
            sheetName={t('sheet.all_vehicles_fleet_claims')}
            dataName={allClaims.resource}
            resourceName={allClaims.resource}
            extraQuery={allClaims?.extraQuery}
            isArchived={false}
            buttonName={allClaims?.buttonName}
          />
        )
      },
      {
        key: 'export_2',
        label: (
          <ExportXlsxButton
            exportUrl={`/${isOpenClaim?.url}`}
            populate={isOpenClaim?.populate}
            sheetName={t('sheet.all_vehicles_fleet_claims')}
            dataName={isOpenClaim.resource}
            resourceName={isOpenClaim.resource}
            extraQuery={isOpenClaim?.extraQuery}
            isArchived={false}
            buttonName={isOpenClaim?.buttonName}
          />
        )
      },
      {
        key: 'export_3',
        label: (
          <ExportXlsxButton
            exportUrl={`/${isClosedClaim?.url}`}
            populate={isClosedClaim?.populate}
            sheetName={t('sheet.all_vehicles_fleet_claims')}
            dataName={isClosedClaim.resource}
            resourceName={isClosedClaim.resource}
            extraQuery={isClosedClaim?.extraQuery}
            isArchived={false}
            buttonName={isClosedClaim?.buttonName}
          />
        )
      }
    ]
  };

  const isDeletedQuery = '&deleted=true';
  const isEntryQuery = '&deleted=false';
  const programmesBaseQuery = `company=${company}&fleet_type=LIGHT_VEHICLE,HEAVY_VEHICLE,OTHER_VEHICLE&missing_documents=&fields=_id,deleted`;

  const allVehicles = {
    url: `exports/company/${company}/fleet-type/LIGHT_VEHICLE`,
    buttonName: t('buttons.exports.programmes.all_fleet_types'),
    extraQuery: programmesBaseQuery,
    resource: 'vehicles',
    populate: 'entity,site,principal_driver,contract,company'
  };

  const isEntryProgram = {
    ...allVehicles,
    buttonName: t('buttons.exports.programmes.all_entry_fleet_types'),
    extraQuery: `${programmesBaseQuery}${isEntryQuery}`
  };
  const allDeletedPrograms = {
    ...allVehicles,
    buttonName: t('buttons.exports.programmes.all_deleted_fleet_types'),
    extraQuery: `${programmesBaseQuery}${isDeletedQuery}`
  };

  const menuProgramsExports = {
    items: [
      {
        key: 'title',
        label: (
          <Text strong>
            <InfoCircleOutlined style={{ marginRight: '18px' }} />
            {t('buttons.exports.all_outside_automission')}
          </Text>
        )
      },
      {
        key: 'export',
        label: (
          <ExportXlsxButton
            exportUrl={`/${allVehicles?.url}`}
            populate={allVehicles?.populate}
            sheetName={t('sheet.all_vehicles_fleet')}
            dataName={allVehicles.resource}
            resourceName={allVehicles.resource}
            extraQuery={allVehicles?.extraQuery}
            isArchived={false}
            exportAll
            buttonName={allVehicles?.buttonName}
          />
        )
      },
      {
        key: 'export_2',
        label: (
          <ExportXlsxButton
            exportUrl={`/${isEntryProgram?.url}`}
            populate={isEntryProgram?.populate}
            sheetName={t('sheet.all_vehicles_fleet')}
            dataName={isEntryProgram.resource}
            resourceName={isEntryProgram.resource}
            extraQuery={isEntryProgram?.extraQuery}
            isArchived={false}
            exportAll
            buttonName={isEntryProgram?.buttonName}
          />
        )
      },
      {
        key: 'export_3',
        label: (
          <ExportXlsxButton
            exportUrl={`/${allDeletedPrograms?.url}`}
            populate={allDeletedPrograms?.populate}
            sheetName={t('sheet.all_vehicles_fleet')}
            dataName={allDeletedPrograms.resource}
            resourceName={allDeletedPrograms.resource}
            extraQuery={allDeletedPrograms?.extraQuery}
            isArchived={false}
            exportAll
            buttonName={allDeletedPrograms?.buttonName}
          />
        )
      }
    ]
  };

  return (
    <Dropdown
      menu={target === 'programmes' ? menuProgramsExports : menuClaimExports}
    >
      <DownCircleOutlined
        style={{
          marginLeft: '18px',
          ...(target === 'programmes' ? { color: 'white' } : {})
        }}
      />
    </Dropdown>
  );
};

ExportAllVehicleFleetsMenu.propTypes = {
  target: PropTypes.string.isRequired
};
