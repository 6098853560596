import { useTranslation } from 'react-i18next';

export const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('claims.form.accounting_provision'),
      dataIndex: 'accounting_provision',
      key: 'accounting_provision',
      render: (accounting_provision) =>
        (accounting_provision &&
          `${accounting_provision?.toFixed(2) || 0} €`) ||
        '0 €'
    },
    {
      title: t('claims.form.ageing_coefficient'),
      dataIndex: 'ageing_coefficient',
      key: 'ageing_coefficient',
      render: (ageing_coefficient) =>
        (ageing_coefficient && `${ageing_coefficient}`) || '0'
    }
  ];
};
