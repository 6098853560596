import { Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useColumns } from '../HardwareInsuranceClaim/columns';
import { FilterByMissingDocuments } from '../../components/FilterByMissingDocuments';
import { useActionColumn } from '../actionColumn';

/**
 * @Component
 *
 * A component that lists 'Legal Protection'.
 * `ListLegalProtectionInsuranceClaim` is responsible for fetching and listing 'Legal Protection'
 * @returns {JSX.Element} The rendered `ListLegalProtectionInsuranceClaim` component with filtering capabilities.
 */

export const ListLegalProtectionInsuranceClaim = () => {
  const [isMissingDocuments, setIsMissingDocuments] = useState('');
  const [resetFilters, setResetFilters] = useState(false);
  const [extraFilter, setExtraFilter] = useState('');
  const { t } = useTranslation();
  const actionColumnUrl = 'legal-protection-insurances';
  const url = 'legal_protection_insurance_claims';

  const [isClosedButton, setIsClosedButton] = useState(false);
  const { company } = useAuthContext();
  const resourceName = 'legal_protection_insurance_claims';

  const dbExtraQueryFilter = `company=${company}&${
    isClosedButton ? 'claim_status=CLOSED' : 'claim_status!=CLOSED'
  }`;
  const populate = 'entity,site,entry_id,company';

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = Number(params.get('p') || 1);
  const pageSize = Number(params.get('pS') || 10);

  let filterParameter = '';

  const [columns, loading] = useColumns(
    resourceName,
    dbExtraQueryFilter,
    populate
  );
  const actionColumn = useActionColumn(actionColumnUrl, url);

  const onFilterMissingDocuments = (missingDocuments) => {
    setIsMissingDocuments(missingDocuments === false ? '' : missingDocuments);
  };

  useEffect(() => {
    if (resetFilters) {
      filterParameter = '';
      setResetFilters(false);
    }
    filterParameter = `missing_documents=${isMissingDocuments}__`;
    setExtraFilter(filterParameter);
    navigate({
      pathname: '/claims/legal-protection-insurances',
      search: `?&p=${currentPage}&pS=${pageSize}&f=${filterParameter}`
    });
  }, [navigate, isMissingDocuments, resetFilters]);

  if (loading) return <Spin fullscreen="true" />;

  return (
    <ListResource
      extraQuery={dbExtraQueryFilter}
      resourceName={resourceName}
      exportUrl={`exports/company/${company}/claim/programme-type/LEGAL_PROTECTION_INSURANCE`}
      importUrl={`imports-claim-legal-protection-insurances/company/${company}`}
      exportTemplateUrl="export-claim-templates/template/legal-protection-insurance-claim"
      tradKey="claims"
      columns={[...columns, ...actionColumn]}
      withExtraFilters={
        <FilterByMissingDocuments
          onChange={onFilterMissingDocuments}
          setResetFilters={setResetFilters}
        />
      }
      populate={populate}
      editAction={false}
      showAction={false}
      deleteAction={false}
      sheetName={t('sheet.hardware_insurance_claims')}
      setIsClosedButton={setIsClosedButton}
      headers={[]}
      noCreateButton
      noArchiveFilter
      extraFilter={extraFilter}
      scroll={{ x: 3800 }}
    />
  );
};
