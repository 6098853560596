import { AlertOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { Text } = Typography;

/**
 * Renders a delete button for deleting a claim from the database.
 * @component
 *
 * @param {string} id - The ID of the claim to be deleted.
 * @param {string} baseUrl - The base URL for the API endpoint.
 * @returns {JSX.Element} - The delete button component.
 */
export const renderDeleteFromDbButton = (id, baseUrl) => {
  const { t } = useTranslation();
  const [loading, setIsLoading] = useState();
  const { dispatchAPI, user } = useAuthContext();
  const [isVisible, setIsVisible] = useState(false);
  const { message } = useErrorMessage();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === 'admins:SUPER-ADMIN') setIsVisible(true);
  }, [user]);

  const deleteFromDB = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('DELETE', {
        url: `${baseUrl}/from-db/${id}`
      });
      setIsLoading(false);
      navigate(-1);
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  };

  return isVisible ? (
    <Popconfirm
      title={
        <Text strong style={{ color: 'red' }}>
          {t('delete_from_db.message')}
        </Text>
      }
      okText={t('buttons.delete_permanently')}
      okButtonProps={{ type: 'danger' }}
      cancelText={t('claims.popconfirm.cancel')}
      onConfirm={deleteFromDB}
      icon={<AlertOutlined style={{ color: 'red' }} />}
      placement="bottom"
    >
      <Button loading={loading} icon={<DeleteOutlined />}>
        {`${t('buttons.delete_permanently')}`}
      </Button>
    </Popconfirm>
  ) : null;
};
