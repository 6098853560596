import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Form, Row, Spin, notification } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import FilesManager from './FilesManager/FilesManagerWithDisplay';
import { handleFormDataWithFiles } from './utils/handleFormDataWithFiles';
import { handleFileActionsOnFetch } from './utils/handleFileActionsOnFetch';
import { checkDraggerTypes } from './utils/checkDraggerTypes';
import { useDownloadDocument } from '../../utils/downloadDoc';
import { checkMandatoryDocuments } from './utils/checkMandatoryDocuments';
import { ErrorModal } from '../ErrorModal/ErrorModal';
import { requiredFilesConditions } from '../../routes/programmes/MachineryBreakage/utils/documentsConditions';

/**
 * `CreateUpdateContainer` is a React component designed to create or update resources.
 * It provides a form with fields specified in the `fields` prop and has the capability to manage file uploads.
 * The component's main function is to create or update data on a given API endpoint specified by `baseUrl`.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the container.
 * @param {Array} props.fields - The fields for the form.
 * @param {boolean} props.loadingFields - Indicates if the fields are loading.
 * @param {string} props.resource - The resource name.
 * @param {string} props.baseUrl - The base URL for API requests.
 * @param {Object} props.config - The configuration object.
 * @param {Object} props.formExtra - Extra form configuration.
 * @param {string} props.tradKey - The translation key.
 * @param {string} props.submitLabel - The label for the submit button.
 * @param {Function} props.customSubmit - Custom submit function.
 * @param {boolean} props.isParentLoading - Indicates if the parent is loading.
 * @param {boolean} props.withFilesManager - Indicates if the files manager is enabled.
 * @param {ReactNode} props.extraTitle - Extra title for the container.
 * @param {string} props.idWithoutParams - The ID without parameters.
 * @param {boolean} props.withPageHeaderCustom - Indicates if the custom page header is enabled.
 * @param {string} props.title - The title for the container.
 * @param {Array} props.draggerFilesKeysMandatory - The mandatory dragger file keys.
 * @param {string} props.populate - The populate string.
 * @param {string} props.urlFileKeys - The URL for file keys.
 * @param {Array} props.draggerFilesKeysOverRide - The overridden dragger file keys.
 * @param {Array} props.mandatoryDocuments - The mandatory documents.
 * @param {boolean} props.noDelete - Indicates if delete is disabled.
 * @param {string} props.messageOnSuccess - The success message.
 * @param {boolean} props.isModal - Indicates if the container is a modal.
 * @param {Function} props.customModal - Custom modal function.
 * @param {Function} props.setReload - Reload function.
 * @param {boolean} props.reload - Indicates if the container should reload.
 * @param {Function} props.setCurrentEntityContext - Set current entity context function.
 * @param {string} props.machinaryStatusType - The machinery status type.
 * @param {boolean} props.draggerNoRemoveButton - Indicates if the dragger remove button is disabled.
 * @param {boolean} props.allFilesAreMandatory - Indicates if all files are mandatory.
 * @param {Function} props.allFilesAreMandatoryConditions - The conditions for all files to be mandatory.
 * @param {Function} props.customCancel - Custom cancel function.
 * @param {boolean} props.noDynamiqueRoute - Indicates if dynamic route is disabled.
 * @param {Object} props.parentForm - The parent form.
 * @param {string} props.confirmationCheckboxMessage - The confirmation checkbox message.
 * @param {Function} props.customPostCreate - Custom post create function.
 * @param {boolean} props.noReturnButton - Indicates if the return button is disabled.
 * @param {string} props.layout - The form layout.
 * @param {boolean} props.multipleFilesDragger - Indicates if multiple files are allowed in the dragger.
 * @param {React.Ref} ref - The ref for the form.
 * @returns {ReactNode} The CreateUpdateContainer component.
 */
export const CreateUpdateContainer = forwardRef(
  (
    {
      purpose,
      fields,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      withFilesManager,
      extraTitle,
      idWithoutParams,
      withPageHeaderCustom,
      title,
      draggerFilesKeysMandatory,
      populate,
      urlFileKeys,
      draggerFilesKeysOverRide,
      mandatoryDocuments,
      noDelete,
      messageOnSuccess,
      isModal,
      customModal,
      setReload,
      reload,
      setCurrentEntityContext,
      machinaryStatusType,
      draggerNoRemoveButton,
      allFilesAreMandatory,
      allFilesAreMandatoryConditions,
      customCancel,
      noDynamiqueRoute,
      parentForm,
      confirmationCheckboxMessage,
      customPostCreate,
      noReturnButton,
      layout,
      multipleFilesDragger
    },
    ref
  ) => {
    const [localForm] = Form.useForm(); // Create a new form instance
    const form = parentForm || localForm;
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [draggerFilesList, setDraggerFilesList] = useState([]);
    const [fieldsFilesList, setFieldsFileList] = useState([]);
    const [filesConfiguration, setFilesConfiguration] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [draggerFilesKeys, setDraggerFilesKeys] = useState([]);
    const [allFilesAreMandatoryArray, setAllFilesAreMandatoryArray] =
      useState(null);
    const [isFileManagerDisabled, setIsFileManagerDisabled] =
      useState(machinaryStatusType);
    const { onGetResource, onCreateResource, onUpdateResource } = config;
    const { downloadDocument, viewDocument } = useDownloadDocument();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
      if (allFilesAreMandatory) {
        if (allFilesAreMandatoryConditions) {
          return allFilesAreMandatoryConditions(
            draggerFilesKeys,
            setAllFilesAreMandatoryArray
          );
        }
        return setAllFilesAreMandatoryArray(draggerFilesKeys);
      }
      return setAllFilesAreMandatoryArray(null);
    }, [draggerFilesKeys]);

    const getResourceFilesKeys = async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${urlFileKeys || baseUrl}/enums`
        });
        if (data.fileKeys && data.fileKeys.length !== 0) {
          const filteredKeys = data.fileKeys.filter((enumItem) => {
            if (machinaryStatusType) {
              return (
                enumItem.source === 'dragger' &&
                requiredFilesConditions(machinaryStatusType).includes(
                  enumItem.key
                )
              );
            }
            return enumItem.source === 'dragger';
          });
          const isValidFilter = filteredKeys.length !== 0;
          setIsFileManagerDisabled(!isValidFilter);
          setDraggerFilesKeys(filteredKeys.map((enumItem) => enumItem.key));
          const transformedObject = {};

          data.fileKeys.forEach((item) => {
            if (item.source === 'field') {
              transformedObject[item.key] = [];
            }
          });
          if (draggerFilesKeysOverRide) {
            setDraggerFilesKeys(draggerFilesKeysOverRide);
          }
          setFieldsFileList(transformedObject);
        }
        return true;
      } catch (e) {
        return message(e);
      }
    };

    const updateResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();

      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );
      const values =
        onUpdateResource && onUpdateResource.setBody
          ? onUpdateResource.setBody(body)
          : body;

      const mandatoryDocumentsArePresent = checkMandatoryDocuments(
        allFilesAreMandatory || mandatoryDocuments,
        filesConfiguration
      );

      const valuesToAppend = {
        ...values,
        missing_documents: mandatoryDocumentsArePresent
      };

      formData.append(
        'values',
        JSON.stringify({
          ...valuesToAppend
        })
      );

      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${idWithoutParams || id}`,
          body: formData
        });
        if (isModal) {
          customModal();
          setReload(!reload);
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const createResource = async (body, files) => {
      setIsSubmitting(true);
      const formData = new FormData();
      handleFormDataWithFiles(
        files,
        draggerFilesList,
        formData,
        filesConfiguration,
        purpose
      );
      const values =
        onCreateResource && onCreateResource.setBody
          ? onCreateResource.setBody(body)
          : body;

      formData.append(
        'values',
        JSON.stringify({
          ...values
        })
      );

      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body: formData
        });
        if (messageOnSuccess) {
          notification.success({ message: t('export.messages.success') });
        }
        if (isModal) {
          customModal();
          setReload(!reload);
        } else if (customPostCreate) {
          customPostCreate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        setIsSubmitting(false);
        message(e);
      }
    };

    const deleteFile = async (fileID) => {
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${id || idWithoutParams}/${fileID}`
        });
        setRefresh(!refresh);
      } catch (e) {
        message(e);
      }
    };

    const getResource = useCallback(async () => {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${idWithoutParams || id}${populate}`
        });
        if (setCurrentEntityContext && data.entity) {
          setCurrentEntityContext(data.entity);
        }
        if (data.documents) {
          handleFileActionsOnFetch(
            data,
            setFieldsFileList,
            setDraggerFilesList,
            setFilesConfiguration,
            dispatchAPI,
            message
          );
          form.setFieldsValue(
            onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data
          );
        } else {
          form.setFieldsValue(
            onGetResource && onGetResource.setFields
              ? onGetResource.setFields(data)
              : data
          );
        }
      } catch (e) {
        message(e);
      }
    }, [purpose, id, loadingFields, baseUrl, idWithoutParams]);

    useEffect(() => {
      if (!loadingFields) {
        setIsLoading(true);
        (async () => {
          try {
            if (
              (purpose === 'edit' || purpose === 'amendment') &&
              (id || idWithoutParams)
            ) {
              await getResource();
            }
            await getResourceFilesKeys();
          } catch (error) {
            message(error);
          } finally {
            setIsLoading(false);
          }
        })();
      }
    }, [getResource, id, idWithoutParams, machinaryStatusType, refresh]);

    const handleSubmit = async (values) => {
      const extractedFileKeys = filesToUpload.map(
        (fileObject) => fileObject.file
      );
      const mandatoryDocumentsArePresent = checkMandatoryDocuments(
        allFilesAreMandatoryArray || mandatoryDocuments,
        filesConfiguration
      );
      const boolean = checkDraggerTypes(draggerFilesList, filesConfiguration);
      switch (true) {
        case !mandatoryDocumentsArePresent:
          return setIsOpen(true);
        case !boolean:
          return message(t('missing_types'));
        case customSubmit:
          return customSubmit(values, extractedFileKeys);
        case purpose === 'edit':
          await updateResource(values, extractedFileKeys);
          return true;
        default:
          await createResource(values, extractedFileKeys);
      }

      return true;
    };
    const generateFieldsMemoized = useCallback(
      useGenerateFormItem(
        fieldsFilesList,
        setFieldsFileList,
        filesConfiguration,
        setFilesConfiguration,
        purpose,
        deleteFile,
        setFilesToUpload,
        downloadDocument,
        viewDocument,
        draggerNoRemoveButton
      ),
      [fieldsFilesList, filesConfiguration]
    );

    return (
      <>
        {withPageHeaderCustom && (
          <PageHeaderCustom
            title={title || t(`${resource}.form.title.${purpose}`)}
            noDynamiqueRoute={noDynamiqueRoute}
            noReturnButton={noReturnButton}
          />
        )}
        {extraTitle}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              layout={layout}
              {...(layout !== 'vertical' && formItemLayout)}
              onFinish={handleSubmit}
              form={form}
            >
              <>
                {fields.map((field) =>
                  generateFieldsMemoized(tradKey || resource, field)
                )}
                {formExtra}
              </>
              {withFilesManager && (
                <FilesManager
                  filesList={draggerFilesList}
                  draggerFilesKeysMandatory={
                    allFilesAreMandatoryArray || draggerFilesKeysMandatory
                  }
                  mandatoryDocuments={
                    allFilesAreMandatoryArray || mandatoryDocuments
                  }
                  setFilesList={setDraggerFilesList}
                  filesKeys={draggerFilesKeys}
                  filesConfiguration={filesConfiguration}
                  setFilesConfiguration={setFilesConfiguration}
                  purpose={purpose}
                  deleteFile={deleteFile}
                  noDelete={noDelete}
                  isFileManagerDisabled={isFileManagerDisabled}
                  setRefresh={setRefresh}
                  multipleFilesDragger={multipleFilesDragger}
                />
              )}
              {confirmationCheckboxMessage && (
                <Form.Item
                  required
                  label={t(`interruption_golf.form.to_check`)}
                >
                  <Checkbox required>{confirmationCheckboxMessage}</Checkbox>
                </Form.Item>
              )}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 8px' }}
                    type="link"
                    danger
                    onClick={() => {
                      if (customCancel) {
                        return customCancel();
                      }
                      if (isModal) {
                        return customModal();
                      }
                      return navigate(-1);
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                  >
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
            <ErrorModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              title={t('errors.message.missing_mandatory_documents')}
              details={allFilesAreMandatoryArray || mandatoryDocuments}
              tradKey="files.keys"
            />
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  withFilesManager: PropTypes.bool,
  idWithoutParams: PropTypes.string,
  withPageHeaderCustom: PropTypes.bool,
  extraTitle: PropTypes.element,
  title: PropTypes.string,
  draggerFilesKeys: PropTypes.arrayOf(PropTypes.string),
  draggerFilesKeysMandatory: PropTypes.arrayOf(PropTypes.string),
  populate: PropTypes.string,
  urlFileKeys: PropTypes.string,
  mandatoryDocuments: PropTypes.arrayOf(PropTypes.string),
  draggerFilesKeysOverRide: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
  noDelete: PropTypes.bool,
  messageOnSuccess: PropTypes.bool,
  isModal: PropTypes.bool,
  customModal: PropTypes.func,
  setReload: PropTypes.func,
  reload: PropTypes.bool,
  setCurrentEntityContext: PropTypes.func,
  machinaryStatusType: PropTypes.string,
  draggerNoRemoveButton: PropTypes.bool,
  allFilesAreMandatory: PropTypes.bool,
  allFilesAreMandatoryConditions: PropTypes.func,
  customCancel: PropTypes.func,
  noDynamiqueRoute: PropTypes.bool,
  parentForm: PropTypes.shape({}),
  confirmationCheckboxMessage: PropTypes.string,
  customPostCreate: PropTypes.func,
  noReturnButton: PropTypes.bool,
  layout: PropTypes.string,
  multipleFilesDragger: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  withFilesManager: true,
  idWithoutParams: null,
  withPageHeaderCustom: true,
  extraTitle: null,
  title: null,
  draggerFilesKeys: [],
  draggerFilesKeysMandatory: [],
  populate: '',
  urlFileKeys: null,
  mandatoryDocuments: [],
  draggerFilesKeysOverRide: null,
  type: null,
  noDelete: false,
  messageOnSuccess: false,
  isModal: false,
  customModal: null,
  setReload: () => {},
  reload: false,
  setCurrentEntityContext: null,
  machinaryStatusType: null,
  draggerNoRemoveButton: false,
  allFilesAreMandatory: false,
  allFilesAreMandatoryConditions: null,
  customCancel: null,
  noDynamiqueRoute: false,
  parentForm: null,
  confirmationCheckboxMessage: null,
  customPostCreate: null,
  noReturnButton: false,
  layout: null,
  multipleFilesDragger: false
};
