import { useTranslation } from 'react-i18next';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { Conversations } from './Conversations/Conversations';
import { useAuthContext } from '../../contexts/AuthContext';
import ConversationsBoard from './ConversationsBoard/ConversationsBoard';
/**
 * `Messages` is a React component that renders a messaging interface.
 * It displays different content based on the user's role.
 *
 * The component uses Ant Design's `Tabs` for tabbed navigation and custom components like `ContentCustom` and `PageHeaderCustom`
 * for layout and UI elements. The tabs and their content are dynamically generated based on the user's role and access permissions.
 *
 *
 * @component
 * @returns {JSX.Element} A JSX element that renders the appropriate messaging and notes interface based on the user's role.
 */
export const Messages = () => {
  const { t } = useTranslation();
  const { companyName, user } = useAuthContext();
  const isAdmin = ['admins:SUPER-ADMIN', 'admins:ADMIN'].includes(user.role);

  return (
    <>
      <PageHeaderCustom
        title={t('messages.title')}
        withoutBreadCrumbs
        withoutNavigateBack
      />
      <ContentCustom style={{ overflowY: 'hidden' }}>
        {companyName === 'UNIT' && isAdmin ? (
          <ConversationsBoard />
        ) : (
          <Conversations />
        )}
      </ContentCustom>
    </>
  );
};
