import { ClearOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Renders the Close Claim button component conditionally based on the closure status and balance conditions.
 * @component
 * @param {boolean} renderCondition - Flag indicating closure status.
 * @param {Function} closureValidation - Function to handle closure validation.
 * @param {boolean} accountingBalanced - Flag indicating accounting balance status.
 * @param {boolean} feesAppealsBalanced - Flag indicating fees appeals balance status.
 * @returns {?React.Component} The rendered Close Claim button component, or null if the closure status is false.
 */
const renderCloseClaimButton = (
  renderCondition,
  closureValidation,
  accountingBalanced,
  feesAppealsBalanced
) => {
  const { t } = useTranslation();

  if (renderCondition) {
    return (
      <Tooltip
        title={
          (!accountingBalanced || !feesAppealsBalanced) &&
          t(`buttons.close_claim`)
        }
      >
        <Popconfirm
          title={t('claims.popconfirm.title')}
          okText={t('claims.popconfirm.confirm')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('claims.popconfirm.cancel')}
          onConfirm={closureValidation}
          icon={<WarningOutlined />}
          disabled={!accountingBalanced || !feesAppealsBalanced}
        >
          <Button icon={<ClearOutlined />}>
            {`${t('claims.form.enclose')}`}{' '}
          </Button>
        </Popconfirm>
      </Tooltip>
    );
  }
  return null;
};

export default renderCloseClaimButton;
