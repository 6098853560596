import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Dropdown, Flex, Input, Switch } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ExportXlsxButton } from '../ExportXlsxButton/ExportXlsxButton';
import { ImportXlsxButton } from '../ImportXlsxButton/ImportXlsxButton';
import { ExportXlsxTemplateButton } from '../ExportXlsxTemplateButton/ExportXlsxTemplateButton';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { AddIcon } from '../../utils/constants/customIcons';

const { Search } = Input;

/**
 * A reusable component for displaying a list of resources with various options like searching, filtering, exporting, and more.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.resourceName - The name of the resource being displayed.
 * @param {string} [props.tradKey] - The translation key for the resource title.
 * @param {string} [props.dataToFetch] - Additional data to fetch for the resource.
 * @param {Array<Object>} props.columns - The column configurations for the resource table.
 * @param {boolean} [props.customActionColumn] - Indicates whether to display a custom action column.
 * @param {Array<Object>} [props.headers] - The headers for exporting data.
 * @param {string} [props.extraQuery] - Additional query parameters for data fetching.
 * @param {element} [props.extraHeader] - Additional header element.
 * @param {element} [props.extraButtons] - Additional buttons to display.
 * @param {string} [props.exportUrl] - The URL for exporting resource data.
 * @param {string} [props.populate] - The population field for the resource data.
 * @param {boolean|Object} [props.withCreateButton] - Indicates whether to display a create button or an object with button customization options.
 * @param {boolean} [props.withUploadButton] - Indicates whether to display an upload button.
 * @param {boolean} [props.withPageHeader] - Indicates whether to display a page header.
 * @param {boolean} [props.withSearchBar] - Indicates whether to display a search bar.
 * @param {boolean} [props.forceRefresh] - Indicates whether to force a data refresh.
 * @param {boolean|Object} [props.editAction] - Indicates whether to enable edit actions or an object with pathname customization.
 * @param {boolean|Object} [props.duplicateAction] - Indicates whether to enable duplicate actions or an object with pathname customization.
 * @param {boolean|Object} [props.printAction] - Indicates whether to enable print actions or an object with pathname customization.
 * @param {boolean|Object} [props.showAction] - Indicates whether to enable show actions or an object with pathname customization.
 * @param {boolean} [props.deleteAction] - Indicates whether to enable delete actions.
 * @param {boolean|Object} [props.onDoubleClickAction] - Indicates whether to enable double-click actions or an object with action customization.
 * @param {Object} [props.scroll] - Scroll configuration for the resource table.
 * @param {Object} [props.expandable] - Expandable configuration for the resource table.
 * @param {string} [props.path] - The base path for resource navigation.
 * @param {string} [props.rowKey] - The key to use for identifying rows in the table.
 * @param {function} [props.formatter] - A custom formatter function for table data.
 * @param {element} [props.withExtraPageHeader] - Additional page header element.
 * @param {element} [props.withExtraFilters] - Additional filter elements.
 * @param {function} [props.setLoaded] - A function to set the loaded state.
 * @param {boolean} [props.loaded] - The loaded state.
 * @param {string} [props.sheetName] - The name of the sheet for exporting data.
 * @param {string} [props.importUrl] - The URL for importing data.
 * @param {string} [props.exportTemplateUrl] - The URL for exporting data templates.
 * @returns {ReactElement} The rendered `ListResource` component.
 */

export const ListResource = ({
  resourceName,
  tradKey,
  dataToFetch,
  columns,
  customActionColumn,
  headers,
  children,
  populate,
  extraQuery,
  extraHeader,
  extraButtons,
  exportUrl,
  exporButton2,
  exportAllButton,
  withCreateButton,
  withUploadButton,
  withPageHeader,
  withSearchBar,
  forceRefresh,
  setForceRefresh,
  editAction,
  showAction,
  duplicateAction,
  printAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  withExtraPageHeader,
  withExtraFilters,
  setLoaded,
  loaded,
  sheetName,
  importUrl,
  exportTemplateUrl,
  template,
  noCreateButton,
  permissionReintegrate,
  noArchiveFilter,
  onlyExport,
  customDelete,
  extraFilter,
  setIsArchivatedPage,
  setIsClosedButton,
  rowSelection,
  noExitSwitch
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);
  const [isArchived, setIsArchived] = useState(false);
  const [unarchive, setUnarchive] = useState(false);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);
  const menu = {
    items: [
      {
        key: 'export',
        label: (
          <ExportXlsxButton
            exportUrl={`/${exportUrl || resourceName}`}
            populate={populate}
            sheetName={sheetName}
            dataName={template || resourceName}
            resourceName={resourceName}
            extraQuery={extraQuery}
            isArchived={isArchived}
          />
        )
      },
      ...(exportAllButton
        ? [
            {
              key: 'export_all',
              label: (
                <ExportXlsxButton
                  dataName={template || resourceName}
                  headers={headers}
                  exportUrl={`/${exportUrl || resourceName}`}
                  populate={populate}
                  extraQuery={extraQuery}
                  sheetName={sheetName}
                  resourceName={resourceName}
                  isArchived={isArchived}
                  exportAll
                />
              )
            }
          ]
        : []),
      ...(exporButton2
        ? [
            {
              key: 'export_2',
              label: (
                <ExportXlsxButton
                  dataName={template || resourceName}
                  exportUrl={`/${exporButton2?.url || resourceName}`}
                  fileName={`${resourceName}.csv`}
                  populate={populate}
                  extraQuery={exporButton2?.extraQuery || extraQuery}
                  sheetName={sheetName}
                  resourceName={resourceName}
                  isArchived={isArchived}
                  exportAll
                  buttonName={exporButton2?.buttonName}
                />
              )
            }
          ]
        : []),
      ...(!onlyExport
        ? [
            {
              key: 'import',
              label: (
                <ImportXlsxButton
                  resourceName={template || resourceName}
                  importUrl={importUrl}
                  setForceRefresh={() => setForceRefresh()}
                  forceRefresh={forceRefresh}
                />
              )
            },
            {
              key: 'exportTemplate',
              label: (
                <ExportXlsxTemplateButton
                  dataName={template || resourceName}
                  url={`/${exportTemplateUrl || resourceName}`}
                  sheetName={sheetName}
                />
              )
            }
          ]
        : [])
    ]
  };
  return (
    <>
      {withPageHeader && (
        <PageHeaderCustom
          title={t(`${tradKey || resourceName}.title`)}
          extra={extraHeader}
        />
      )}
      <ContentCustom>
        {withExtraPageHeader}
        <Flex
          justify={withSearchBar ? 'space-between' : 'end'}
          gap="small"
          align="center"
          wrap="wrap"
        >
          {withSearchBar && (
            <Flex align="center" gap="small">
              <Search
                allowClear
                placeholder={t('placeholder.search')}
                defaultValue={searchValue}
                onSearch={(value) => searchResource(value)}
                style={{ minWidth: 190, maxWidth: 240 }}
              />
              {withExtraFilters}
            </Flex>
          )}
          {!withCreateButton && headers && (
            <Dropdown menu={menu}>
              <Button type="link">
                <MenuOutlined
                  style={{ fontSize: 16, color: 'var(--textColor)' }}
                />
              </Button>
            </Dropdown>
          )}

          <Flex gap="small" align="center" wrap="wrap">
            {extraButtons}
            {setIsClosedButton && noArchiveFilter ? (
              <Switch
                checkedChildren={t('buttons.closed')}
                unCheckedChildren={t('buttons.active')}
                onChange={() => {
                  setIsClosedButton((prev) => !prev);
                }}
              />
            ) : null}
            {withCreateButton && !noArchiveFilter && (
              <Flex gap="middle" align="center">
                {!noExitSwitch && (
                  <Switch
                    checkedChildren={t('buttons.exits')}
                    unCheckedChildren={t('buttons.entries')}
                    onChange={() => {
                      if (setIsArchivatedPage) setIsArchivatedPage(!isArchived);
                      setIsArchived(!isArchived);
                      setUnarchive(!unarchive);
                    }}
                  />
                )}
                {!isArchived && !noCreateButton && (
                  <Link to={withCreateButton?.path || `${pathname}/create`}>
                    <Button type="primary">
                      {withCreateButton?.buttonText || `${t('buttons.create')}`}
                      &nbsp;
                      {withCreateButton?.buttonIcon || <AddIcon />}
                    </Button>
                  </Link>
                )}
              </Flex>
            )}
            {withUploadButton && headers && (
              <Dropdown menu={menu}>
                <Button type="link">
                  <MenuOutlined
                    style={{ fontSize: 16, color: 'var(--textColor)' }}
                  />
                </Button>
              </Dropdown>
            )}
          </Flex>
        </Flex>
        <Flex align="center" gap="small">
          {children}
        </Flex>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={resourceName}
          dataToFetch={dataToFetch}
          columns={columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          duplicateAction={duplicateAction}
          isArchived={isArchived}
          printAction={printAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
          setLoaded={setLoaded}
          loaded={loaded}
          unarchive={unarchive}
          permissionReintegrate={permissionReintegrate}
          customDelete={customDelete}
          extraFilter={extraFilter}
          rowSelection={rowSelection}
        />
      </ContentCustom>
    </>
  );
};

ListResource.propTypes = {
  resourceName: PropTypes.string.isRequired,
  tradKey: PropTypes.string,
  dataToFetch: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customActionColumn: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  extraQuery: PropTypes.string,
  extraHeader: PropTypes.element,
  extraButtons: PropTypes.element,
  exportUrl: PropTypes.string,
  populate: PropTypes.string,
  withCreateButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      path: PropTypes.string,
      buttonText: PropTypes.string,
      buttonIcon: PropTypes.element
    })
  ]),
  withUploadButton: PropTypes.bool,
  withPageHeader: PropTypes.bool,
  withSearchBar: PropTypes.bool,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  duplicateAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  printAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  withExtraPageHeader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([null])
  ]),
  withExtraFilters: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.oneOf([null])
  ]),
  setLoaded: PropTypes.func,
  loaded: PropTypes.bool,
  sheetName: PropTypes.string,
  importUrl: PropTypes.string,
  exportTemplateUrl: PropTypes.string,
  permissionReintegrate: PropTypes.bool,
  template: PropTypes.string,
  noCreateButton: PropTypes.bool,
  noArchiveFilter: PropTypes.bool,
  onlyExport: PropTypes.bool,
  customDelete: PropTypes.bool,
  setForceRefresh: PropTypes.func,
  extraFilter: PropTypes.string,
  setIsArchivatedPage: PropTypes.func,
  setIsClosedButton: PropTypes.func,
  rowSelection: PropTypes.shape({}),
  noExitSwitch: PropTypes.bool,
  exportAllButton: PropTypes.bool,
  exporButton2: PropTypes.shape({
    url: PropTypes.string,
    buttonName: PropTypes.string,
    extraQuery: PropTypes.string
  })
};

ListResource.defaultProps = {
  tradKey: null,
  headers: null,
  extraQuery: null,
  extraHeader: null,
  extraButtons: null,
  exportUrl: null,
  populate: null,
  customActionColumn: false,
  withCreateButton: true,
  withUploadButton: true,
  withSearchBar: true,
  withPageHeader: true,
  dataToFetch: null,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  duplicateAction: false,
  printAction: false,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id',
  withExtraFilters: undefined,
  withExtraPageHeader: undefined,
  setLoaded: undefined,
  loaded: undefined,
  sheetName: null,
  importUrl: null,
  exportTemplateUrl: null,
  template: null,
  noCreateButton: false,
  permissionReintegrate: true,
  noArchiveFilter: false,
  onlyExport: false,
  customDelete: false,
  extraFilter: undefined,
  setForceRefresh: () => ({}),
  setIsArchivatedPage: null,
  setIsClosedButton: null,
  rowSelection: null,
  noExitSwitch: false,
  exportAllButton: false,
  exporButton2: null
};
