/**
 * `useDraggerProps` is a hook for handling file dragger's properties.
 *
 * @hook
 * @param {Array} filesList - The list of files.
 * @param {Function} setFilesList - Function to set the list of files.
 * @param {Function} message - Function to display a message.
 * @param {Function} t - Function for translation.
 * @returns {Object} Returns an object with properties to control the behavior of file dragger.
 */
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const useDraggerProps = (filesList, setFilesList) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  return {
    multiple: false,
    onRemove: (file) => {
      const index = filesList.indexOf(file);
      const newFileList = filesList.slice();
      newFileList.splice(index, 1);
      setFilesList(newFileList);
    },
    beforeUpload: (file) =>
      new Promise((resolve, reject) => {
        const isFileExist = filesList.some(
          (configFile) => configFile.name === file.name
        );
        if (isFileExist) {
          message(t('existing_file_name'));
          reject();
        } else {
          setFilesList((prev) => {
            const updatedFileList = [...prev, file];
            return updatedFileList;
          });
          resolve(false);
        }
      }),
    showUploadList: false
  };
};
