import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useFiltersDataFetch } from '../../../../components/ColumnsDynamicFilters/useFiltersDatasFetch';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook that returns an array of filtered columns and a loading flag.
 * @hook
 * @param {string} resourceName - The name of the resource.
 * @param {object} dbExtraQueryFilter - Additional query filters for the database.
 * @param {boolean} populate - Flag indicating whether to populate the data.
 * @returns {Array} An array containing the filtered columns and a loading flag.
 */
export const useColumns = (resourceName, dbExtraQueryFilter, populate) => {
  const { t } = useTranslation();
  const { dispatchAPI, company: companyId } = useAuthContext();
  const isMounted = useRef(true);
  const { message } = useErrorMessage();
  const [config, setConfig] = useState({});

  const { fetchFilters, filters, loading } = useFiltersDataFetch(
    resourceName,
    dbExtraQueryFilter,
    resourceName,
    populate
  );
  useEffect(() => {
    fetchFilters();
  }, []);

  const allColumns = [
    {
      title: t('environment_insurances.form.company_name'),
      key: 'company_name',
      dataIndex: ['company', 'name'],
      sorter: true
    },
    {
      title: t('environment_insurances.form.contract_number'),
      key: 'contract_number',
      dataIndex: ['contract', 'contract_number'],
      sorter: true
    },
    {
      title: t('environment_insurances.form.date_added'),
      key: 'date_added',
      dataIndex: ['created_at'],
      render: (created_at) =>
        created_at ? dayjs(created_at).format('DD/MM/YYYY') : '-',
      sorter: true
    },
    {
      title: t('environment_insurances.form.entity'),
      key: 'entity',
      dataIndex: ['entity'],
      filters: (filters?.entity || []).map((entity) => ({
        text: entity.name,
        value: entity._id
      })),
      sorter: true,
      render: (entity) => (entity ? entity.map((e) => e.name).join(', ') : '-')
    },
    {
      title: t('environment_insurances.form.address'),
      key: 'address',
      dataIndex: ['address'],
      filters: (filters?.address || []).map((address) => ({
        text: address,
        value: address
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.postal_code'),
      key: 'postal_code',
      dataIndex: ['postal_code'],
      filters: (filters?.postal_code || []).map((postal_code) => ({
        text: postal_code,
        value: postal_code
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.city'),
      key: 'city',
      dataIndex: ['city'],
      filters: (filters?.city || []).map((city) => ({
        text: city,
        value: city
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.company_registration_number'),
      key: 'company_registration_number',
      dataIndex: ['company_registration_number'],
      filters: (filters?.company_registration_number || []).map((value) => ({
        text: `${value}`,
        value
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.business_domain'),
      key: 'business_domain',
      dataIndex: ['business_domain'],
      filters: (filters?.business_domain || []).map((value) => ({
        text: `${value} €`,
        value
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.territoriality_of_activity'),
      key: 'territoriality_of_activity',
      dataIndex: ['territoriality_of_activity'],
      filters: (filters?.territoriality_of_activity || []).map((value) => ({
        text: t(`environment_insurances.form.enums.${value}`),
        value
      })),
      sorter: true,
      render: (territoriality_of_activity) =>
        territoriality_of_activity
          ? territoriality_of_activity
              .map((territory) =>
                t(`environment_insurances.form.enums.${territory}`)
              )
              .join(', ')
          : '-'
    },
    {
      title: t('environment_insurances.form.global_yearly_turnover'),
      key: 'global_yearly_turnover',
      dataIndex: 'global_yearly_turnover',
      filters: (filters?.global_yearly_turnover || []).map((value) => ({
        text: value,
        value
      })),
      sorter: true
    },
    {
      title: t('environment_insurances.form.number_of_sites_to_cover'),
      key: 'number_of_sites_to_cover',
      dataIndex: 'number_of_sites_to_cover',
      filters: (filters?.number_of_sites_to_cover || []).map((value) => ({
        text: value,
        value
      })),
      sorter: true
    }
  ];

  const getConfig = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programme-configs/${companyId}/environment-insurance-configs`
      });
      setConfig(data[0]);
    } catch (e) {
      if (isMounted.current) message('missing_parameters');
    }
  }, [dispatchAPI, message]);

  useEffect(() => {
    getConfig();
    fetchFilters();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filteredColumns = allColumns.filter((column) => config[column.key]);

  return [filteredColumns, loading] || [];
};
