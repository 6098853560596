import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * @component
 * @param {Boolean} reload - A boolean to trigger a reload of the component.
 * @param {String} baseUrl - The base url of the API.
 * @param {String} resourceNameClaim - The name of the claim resource.
 * @param {String} sumTypeUrl - The type of sum to display.
 * @param {Function} setFeesAppealsBalanced - A function to set the fees appeals balance state.
 * @returns {ReactElement} A component to display the sum.
 */

export const FeesAppealsBalance = ({
  reload,
  baseUrl,
  resourceNameClaim,
  sumTypeUrl,
  setFeesAppealsBalanced
}) => {
  const [total, setTotal] = useState(0);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getTotalAmount = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${baseUrl}/${sumTypeUrl}/${id}/${resourceNameClaim}`
    });
    setTotal(data || 0);
    if (data === 0) {
      setFeesAppealsBalanced(true);
    } else {
      setFeesAppealsBalanced(false);
    }
  };

  useEffect(() => {
    getTotalAmount();
  }, [reload]);

  return <Typography>{total?.toFixed(2)} €</Typography>;
};

FeesAppealsBalance.propTypes = {
  reload: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  resourceNameClaim: PropTypes.string,
  sumTypeUrl: PropTypes.string.isRequired,
  setFeesAppealsBalanced: PropTypes.func.isRequired
};

FeesAppealsBalance.defaultProps = {
  resourceNameClaim: ''
};
