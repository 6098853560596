import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Spin } from 'antd';
import { AuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const { Item } = Form;
const { Option } = Select;

/**
 * SelectManager component for selecting managers.
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.dbKey - The key for identifying the database.
 * @param {boolean} [props.isDisabled=false] - Indicates whether the Select component is disabled.
 * @param {string} [props.currentManager=null] - The ID of the current manager.
 * @param {boolean} [props.isWaitingManagers=false] - Indicates whether the component is waiting for managers call.
 * @returns {JSX.Element} React component.
 */
export const SelectManager = ({
  dbKey,
  isDisabled,
  currentManager,
  isWaitingManagers
}) => {
  const isMounted = useRef(false);
  const [managers, SetManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { company, dispatchAPI } = useContext(AuthContext);
  const { message } = useErrorMessage();
  let role;
  if (dbKey === 'customer_manager') {
    role = { role: 'users:USER' };
  } else {
    role = { role: 'admins:ADMIN' };
  }

  const getManager = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/users/companies/${company}`,
        body: role
      });
      if (isMounted.current) {
        SetManagers(data);
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data.message === 'No users found for the given conditions.'
      ) {
        message(`${dbKey}s_not_found`);
      } else if (e.response && isMounted.current) message(e.response.status);
    } finally {
      if (isMounted.current) setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    (async () => {
      await getManager();
    })();
    return () => {
      isMounted.current = false;
    };
  }, [getManager]);

  if (isLoading || (isWaitingManagers && currentManager === null)) {
    return <Spin />;
  }
  return (
    <>
      <Spin spinning={isLoading} />
      <Item name={[`${dbKey}`]} initialValue={currentManager}>
        <Select
          disabled={isDisabled}
          showSearch
          filterOption={(inputValue, option) =>
            (option?.title ?? '')
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
        >
          {managers
            ? managers.map((manager) => (
                <Option
                  key={manager._id}
                  value={manager._id}
                  title={`${manager.first_name}&nbsp;${manager.last_name}`}
                >
                  {manager.first_name}&nbsp;{manager.last_name}
                </Option>
              ))
            : null}
        </Select>
      </Item>
    </>
  );
};

SelectManager.propTypes = {
  dbKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  currentManager: PropTypes.string,
  isWaitingManagers: PropTypes.bool
};

SelectManager.defaultProps = {
  isDisabled: false,
  currentManager: null,
  isWaitingManagers: false
};
