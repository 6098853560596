import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * @component
 * @param {Boolean} reload - A boolean to trigger a reload of the component.
 * @param {String} resourceNameClaim - The name of the claim resource.
 * @returns {ReactElement} A component to display the sum.
 */

export const GlobalPaymentAmount = ({ reload, resourceNameClaim }) => {
  const [globalPaymentAmount, setGlobalPaymentAmount] = useState(0);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();

  const getGlobalPaymentAmount = async () => {
    const { data } = await dispatchAPI('GET', {
      url: `${resourceNameClaim}/${id}`
    });
    setGlobalPaymentAmount(data.accounting_provision || 0);
  };

  useEffect(() => {
    getGlobalPaymentAmount();
  }, [reload]);

  return <Typography>{globalPaymentAmount.toFixed(2)} €</Typography>;
};

GlobalPaymentAmount.propTypes = {
  reload: PropTypes.bool.isRequired,
  resourceNameClaim: PropTypes.string.isRequired
};
