import { useParams } from 'react-router-dom';
import { Flex } from 'antd';
import renderAcknowledgeReceiptButton from './utils/renderClaimButtons/AcknowledgeReceiptButton';
import renderEditClaimButton from './utils/renderClaimButtons/EditClaimButton';
import renderCloseClaimButton from './utils/renderClaimButtons/CloseClaimButton';
import useRenderConditions from './utils/renderClaimButtons/renderingConditions';
import renderAcknowledgeComplianceButton from './utils/renderClaimButtons/AcknowledgeComplianceButton';
import renderMailingButtonVehicles from './VehiclesFleets/utils/MailingButtonVehicles';
import { renderDeleteFromDbButton } from './utils/renderClaimButtons/DeleteClaimFromDB';

/**
 * Custom hook to generate extra buttons based on current state and conditions.
 * @hook
 * @param {number} current, - The current page.
 * @param {boolean}   isCompliant - Indicates if its already marked as compliant.
 * @param {function}   setIsCompliant, Sets isCompliant.
 * @param {boolean}   edit, - Checks if its on edit mode.
 * @param {boolean}   toEnclosed, - Checks if its ready to enclose.
 * @param {function}   setClosure, - Sets closure.
 * @param {Function}   onChange, - Function managing claim states changes.
 * @param {Object}   productClaim - Object containing claim data.
 * @param {string}   resource - String containing resource info.
 * @param {string}   baseUrl - String containing baseUrl data.
 * @param {string}   reference - String conntaining claim reference .
 * @param {Object}   product, - Object containing product info.
 * @param {string}   urlPathName, - String containing pathName.
 * @param {boolean}   accountingBalanced, - Defines account balance.
 * @param {boolean}   feesAppealsBalanced - Defines fees balance.
 * @returns {Object} Object containing extra buttons JSX element.
 */
export const useExtraButtons = ({
  current,
  isCompliant,
  setIsCompliant,
  edit,
  toEnclosed,
  setClosure,
  onChange,
  productClaim,
  baseUrl,
  urlPathName,
  accountingBalanced,
  feesAppealsBalanced,
  setChangingStatus
}) => {
  const { id } = useParams();
  const { renderConditions } = useRenderConditions({
    isCompliant,
    current,
    edit,
    toEnclosed
  });

  const {
    conditionAcknowledgeReceiptButton,
    conditionAcknowledgeCompliance,
    conditionSpecifiDemand,
    conditionEdit,
    conditionCloseClaim
  } = renderConditions;

  const closureValidation = () => {
    setClosure(true);
    onChange(6, id, edit);
  };

  const extraButtons = (
    <Flex gap="small" wrap="wrap" justify="end">
      {renderAcknowledgeReceiptButton(
        conditionAcknowledgeReceiptButton,
        edit,
        onChange,
        productClaim,
        setChangingStatus
      )}
      {renderAcknowledgeComplianceButton(
        conditionAcknowledgeCompliance,
        setIsCompliant,
        baseUrl,
        productClaim,
        setChangingStatus
      )}
      {renderMailingButtonVehicles(
        conditionSpecifiDemand,
        productClaim,
        baseUrl
      )}
      {renderEditClaimButton(conditionEdit, urlPathName)}
      {renderCloseClaimButton(
        conditionCloseClaim,
        closureValidation,
        accountingBalanced,
        feesAppealsBalanced
      )}
      {renderDeleteFromDbButton(id, baseUrl)}
    </Flex>
  );

  return { extraButtons };
};
