import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Row, Card, Divider } from 'antd';
import { LinksGeneration } from './LinksGeneration';
import { ExportAllVehicleFleetsMenu } from '../claims/VehiclesFleets/utils/ExportAllVehicleFleetsMenu';

/**
 * Renders a tile menu component.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.image - The image to display in the tile.
 * @param {string} props.altImage - The alternative text for the image.
 * @param {Array} props.links - The links to display in the menu.
 * @param {string} props.target - The target of the menu.
 * @returns {JSX.Element} The rendered TileMenu component.
 */
export const TileMenu = ({ image, altImage, links, target }) => {
  const [menu, setMenu] = useState(true);
  const { t } = useTranslation();
  const displayMenu = () => {
    setMenu(!menu);
  };

  const filteredLinks = links.filter((link) => link !== null);

  return (
    <Card
      onClick={displayMenu}
      className={target === 'claims' ? 'widget claims' : 'widget program'}
    >
      <Row className="tile-icon-wrapper" justify="center">
        {image}
      </Row>
      <Divider />
      {menu ? (
        <Row className="tile-content title">
          <h3 style={{ textAlign: 'center' }}>
            {t(`tiles.${altImage}`)}
            {altImage === 'vehicles_fleet' && (
              <ExportAllVehicleFleetsMenu target={target} />
            )}
          </h3>
        </Row>
      ) : (
        <Row
          justify="space-between"
          className="tile-content menu"
          gutter={[4, 2]}
        >
          {filteredLinks.map((link) => (
            <LinksGeneration target={target} link={link} key={link} />
          ))}
        </Row>
      )}
    </Card>
  );
};

TileMenu.propTypes = {
  image: PropTypes.element.isRequired,
  altImage: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.string),
  target: PropTypes.string.isRequired
};

TileMenu.defaultProps = {
  links: []
};
